<template>
    <div class="row" style="padding-bottom:60px">
            <div class="col-md-2 hidden-sm hidden-xs"></div>
            <div class="col-md-8 col-sm-12">
                <div class="welcome">
                    <h4>{{ $t('terms-and-conditions.terms-and-conditions') }}</h4>
                    <button class="terms-accordion" @click="toggleAccordian">{{ $t('terms-and-conditions.part-one') }}</button>
                    <div class="terms-conditions">
                        <div class="terms-body">
                            <ul style="list-style:lower-alpha;">
                                <li>{{ $t('terms-and-conditions.part-one-details-1') }}</li>
                                <li>{{ $t('terms-and-conditions.part-one-details-2') }}</li>
                                <li>{{ $t('terms-and-conditions.part-one-details-3') }}</li>
                                <li>{{ $t('terms-and-conditions.part-one-details-4') }}</li>
                                <li>{{ $t('terms-and-conditions.part-one-details-5') }}</li>
                                <li>{{ $t('terms-and-conditions.part-one-details-6') }}</li>
                                <li>{{ $t('terms-and-conditions.part-one-details-7') }}</li>
                                <li>{{ $t('terms-and-conditions.part-one-details-8') }}</li>
                                <li>{{ $t('terms-and-conditions.part-one-details-9') }}</li>
                                <!-- <li>{{ $t('terms-and-conditions.part-one-details-10') }}</li> -->
                                <li>{{ $t('terms-and-conditions.part-one-details-11') }}</li>
                                <li>{{ $t('terms-and-conditions.part-one-details-12') }}</li>
                            </ul>
                        </div>
                    </div>
                    <button class="terms-accordion" @click="toggleAccordian">{{ $t('terms-and-conditions.part-two') }}</button>
                    <div class="terms-conditions">
                        <div class="terms-body">
                            <ul style="list-style:lower-alpha;">
                                <li>{{ $t('terms-and-conditions.part-two-details-1') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-2') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-3') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-4') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-5') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-6') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-7') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-8') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-9') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-10') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-11') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-12') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-13') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-14') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-15') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-16') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-17') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-18') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-19') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-20') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-21') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-22') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-23') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-24') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-25') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-26') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-27') }}</li>
                                <li>{{ $t('terms-and-conditions.part-two-details-28') }}</li>
                            </ul>
                        </div>
                    </div>
                    <button class="terms-accordion" @click="toggleAccordian">{{  $t('terms-and-conditions.part-three') }}</button>
                    <div class="terms-conditions">
                        <div class="terms-body">
                            <ul style="list-style:lower-alpha;">
                                <li>{{  $t('terms-and-conditions.part-three-details-1') }}
                                    <ul style="list-style:lower-roman;">
                                        <li>{{  $t('terms-and-conditions.part-three-details-1-1') }}</li>
                                        <li>{{  $t('terms-and-conditions.part-three-details-1-2') }}</li>
                                        <li>{{  $t('terms-and-conditions.part-three-details-1-3') }} </li>
                                        <li>{{  $t('terms-and-conditions.part-three-details-1-4') }}</li>
                                    </ul>
                                </li>
                                <li>{{  $t('terms-and-conditions.part-three-details-2') }}</li>
                                <li>{{  $t('terms-and-conditions.part-three-details-3') }}</li>
                                <li>{{  $t('terms-and-conditions.part-three-details-4') }}</li>
                                <li>{{  $t('terms-and-conditions.part-three-details-5') }}</li>
                                <li>{{  $t('terms-and-conditions.part-three-details-6') }}</li>
                            </ul>
                        </div>
                    </div>
                    <button class="terms-accordion" @click="toggleAccordian">{{ $t('terms-and-conditions.part-four') }}</button>
                    <div class="terms-conditions">
                        <div class="terms-body">
                            <ul style="list-style:decimal;">
                                <li>{{ $t('terms-and-conditions.part-four-details-1') }}
                                    <ul style="list-style:lower-alpha;">
                                        <li>{{ $t('terms-and-conditions.part-four-details-1-1') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-four-details-1-2') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-four-details-1-3') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-four-details-1-4') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-four-details-1-5') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-four-details-1-6') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-four-details-1-7') }}</li>
                                    </ul>
                                </li>
                                <li>{{ $t('terms-and-conditions.part-four-details-2') }}
                                    <ul style="list-style:lower-alpha;">
                                        <li>{{ $t('terms-and-conditions.part-four-details-2-1') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-four-details-2-2') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-four-details-2-3') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-four-details-2-4') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-four-details-2-5') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-four-details-2-6') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-four-details-2-7') }}</li>
                                    </ul>
                                </li>
                                <li>{{ $t('terms-and-conditions.part-four-details-3') }}
                                    <ul style="list-style:lower-alpha;">
                                        <li>{{ $t('terms-and-conditions.part-four-details-3-1') }}</li>
                                    </ul>
                                </li>
                                <li>{{ $t('terms-and-conditions.part-four-details-4') }}
                                    <ul style="list-style:lower-roman;">
                                        <li>{{ $t('terms-and-conditions.part-four-details-4-1') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-four-details-4-2') }}
                                            <ul style="list-style:lower-alpha;">
                                                <li>{{ $t('terms-and-conditions.part-four-details-4-2-1') }}</li>
                                                <li>{{ $t('terms-and-conditions.part-four-details-4-2-2') }}</li>
                                                <li>{{ $t('terms-and-conditions.part-four-details-4-2-3') }}</li>
                                                <li>{{ $t('terms-and-conditions.part-four-details-4-2-4') }}</li>
                                                <li>{{ $t('terms-and-conditions.part-four-details-4-2-5') }}</li>
                                                <li>{{ $t('terms-and-conditions.part-four-details-4-2-6') }}</li>
                                            </ul>
                                        </li>
                                        <li>{{ $t('terms-and-conditions.part-four-details-4-3') }}</li>
                                    </ul>
                                </li>
                                <li>{{ $t('terms-and-conditions.part-four-details-5') }}
                                    <ul style="list-style:lower-alpha;">
                                        <li>{{ $t('terms-and-conditions.part-four-details-5-1') }}
                                            <ul style="list-style:lower-roman;">
                                                <li>{{ $t('terms-and-conditions.part-four-details-5-1-1') }}</li>
                                                <li>{{ $t('terms-and-conditions.part-four-details-5-1-2') }}
                                                    <ul style="list-style:lower-alpha;">
                                                        <li>{{ $t('terms-and-conditions.part-four-details-5-1-2-1') }}</li>
                                                        <li>{{ $t('terms-and-conditions.part-four-details-5-1-2-2') }}</li>
                                                        <li>{{ $t('terms-and-conditions.part-four-details-5-1-2-3') }}</li>
                                                        <li>{{ $t('terms-and-conditions.part-four-details-5-1-2-4') }}
                                                            <ul style="list-style:lower-roman;">
                                                                <li>{{ $t('terms-and-conditions.part-four-details-5-1-2-4-1') }}</li>
                                                                <li>{{ $t('terms-and-conditions.part-four-details-5-1-2-4-2') }}</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>{{ $t('terms-and-conditions.part-four-details-5-2') }}
                                            <ul style="list-style:lower-roman;">
                                                <li>{{ $t('terms-and-conditions.part-four-details-5-2-1') }}</li>
                                                <li>{{ $t('terms-and-conditions.part-four-details-5-2-2') }}</li>
                                                <li>{{ $t('terms-and-conditions.part-four-details-5-2-3') }}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <button class="terms-accordion" @click="toggleAccordian">{{ $t('terms-and-conditions.part-five') }}</button>
                    <div class="terms-conditions">
                        <div class="terms-body">
                            <ul style="list-style:decimal;">
                                <li>{{ $t('terms-and-conditions.part-five-details-1') }}
                                    <ul style="list-style:lower-alpha;">
                                        <li>{{ $t('terms-and-conditions.part-five-details-1-1') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-five-details-1-2') }}
                                            <ul style="list-style:lower-roman;">
                                                <li>{{ $t('terms-and-conditions.part-five-details-1-2-1') }}</li>
                                                <li>{{ $t('terms-and-conditions.part-five-details-1-2-2') }}</li>
                                            </ul>
                                        </li>
                                        <li>{{ $t('terms-and-conditions.part-five-details-1-3') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-five-details-1-4') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-five-details-1-5') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-five-details-1-6') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-five-details-1-7') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-five-details-1-8') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-five-details-1-9') }}
                                            <ul style="list-style:lower-roman;">
                                                <li>{{ $t('terms-and-conditions.part-five-details-1-9-1') }}</li>
                                            </ul>
                                        </li>
                                        <li>{{ $t('terms-and-conditions.part-five-details-1-10') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-five-details-1-11') }}</li>
                                    </ul>
                                </li>
                                <li>{{ $t('terms-and-conditions.part-five-details-2') }}
                                    <ul style="list-style:lower-alpha;">
                                        <li>{{ $t('terms-and-conditions.part-five-details-2-1') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-five-details-2-2') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-five-details-2-3') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-five-details-2-4') }}</li>
                                    </ul>
                                </li>
                                <li>{{ $t('terms-and-conditions.part-five-details-3') }}
                                    <ul style="list-style:lower-alpha;">
                                        <li>{{ $t('terms-and-conditions.part-five-details-3-1') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-five-details-3-2') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-five-details-3-3') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-five-details-3-4') }}</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <button class="terms-accordion" @click="toggleAccordian">{{ $t('terms-and-conditions.part-six') }}</button>
                    <div class="terms-conditions">
                        <div class="terms-body">
                            <ul style="list-style:decimal;">
                                <li>{{ $t('terms-and-conditions.part-six-details-1') }}
                                    <ul style="list-style:lower-alpha;">
                                        <li>{{ $t('terms-and-conditions.part-six-details-1-1') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-six-details-1-2') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-six-details-1-3') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-six-details-1-4') }}</li>
                                    </ul>
                                </li>
                                <li>{{ $t('terms-and-conditions.part-six-details-2') }}
                                    <ul style="list-style:lower-alpha;">
                                        <li>{{ $t('terms-and-conditions.part-six-details-2-1') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-six-details-2-2') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-six-details-2-3') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-six-details-2-4') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-six-details-2-5') }}</li>
                                    </ul>
                                </li>
                                <li>{{ $t('terms-and-conditions.part-six-details-3') }}
                                    <ul style="list-style:lower-alpha;">
                                        <li>{{ $t('terms-and-conditions.part-six-details-3-1') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-six-details-3-2') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-six-details-3-3') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-six-details-3-4') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-six-details-3-5') }}</li>
                                    </ul>
                                </li>
                                <li>{{ $t('terms-and-conditions.part-six-details-4') }}
                                    <ul style="list-style:lower-alpha;">
                                        <li>{{ $t('terms-and-conditions.part-six-details-4-1') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-six-details-4-2') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-six-details-4-3') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-six-details-4-4') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-six-details-4-5') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-six-details-4-6') }}
                                            <ul style="list-style:lower-alpha;">
                                                <li>{{ $t('terms-and-conditions.part-six-details-4-6-1') }}</li>
                                                <li>{{ $t('terms-and-conditions.part-six-details-4-6-2') }}</li>
                                                <li>{{ $t('terms-and-conditions.part-six-details-4-6-3') }}</li>
                                            </ul>
                                        </li>
                                        <li>{{ $t('terms-and-conditions.part-six-details-4-7') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-six-details-4-8') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-six-details-4-9') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-six-details-4-10') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-six-details-4-11') }}</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <button class="terms-accordion" @click="toggleAccordian">{{ $t('terms-and-conditions.part-seven') }}</button>
                    <div class="terms-conditions">
                        <div class="terms-body">
                            <ul style="list-style:lower-alpha;">
                                <li>{{ $t('terms-and-conditions.part-seven-details-1') }}</li>
                                <li>{{ $t('terms-and-conditions.part-seven-details-2') }}</li>
                                <li>{{ $t('terms-and-conditions.part-seven-details-3') }}</li>
                                <li>{{ $t('terms-and-conditions.part-seven-details-4') }}</li>
                                <li>{{ $t('terms-and-conditions.part-seven-details-5') }}</li>
                            </ul>
                        </div>
                    </div>
                    <button class="terms-accordion" @click="toggleAccordian">{{ $t('terms-and-conditions.part-eight') }}</button>
                    <div class="terms-conditions">
                        <div class="terms-body">
                            <ul style="list-style:lower-alpha;">
                                <li>{{ $t('terms-and-conditions.part-eight-details-1') }}</li>
                                <li>{{ $t('terms-and-conditions.part-eight-details-2') }}</li>
                                <li>{{ $t('terms-and-conditions.part-eight-details-3') }}</li>
                                <li>{{ $t('terms-and-conditions.part-eight-details-4') }}</li>
                                <li>{{ $t('terms-and-conditions.part-eight-details-5') }}</li>
                                <li>{{ $t('terms-and-conditions.part-eight-details-6') }}</li>
                                <li>{{ $t('terms-and-conditions.part-eight-details-7') }}</li>
                                <li>{{ $t('terms-and-conditions.part-eight-details-8') }}</li>
                                <li>{{ $t('terms-and-conditions.part-eight-details-9') }}
                                    <ul style="list-style:lower-roman;">
                                        <li>{{ $t('terms-and-conditions.part-eight-details-9-1') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-eight-details-9-2') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-eight-details-9-3') }}</li>
                                        <li>{{ $t('terms-and-conditions.part-eight-details-9-4') }}</li>
                                    </ul>
                                </li>
                                <li>{{ $t('terms-and-conditions.part-eight-details-10') }}</li>
                            </ul>
                        </div>
                    </div>
                    <!-- <button class="terms-accordion" @click="toggleAccordian">{{ $t('jackpot-terms.part') }}</button>
                    <div class="terms-conditions">
                        <div class="terms-body">

                            <h5><b>{{ $t('jackpot-terms.part-title') }}</b></h5>
                            <ul style="list-style:lower-alpha;margin-left: -27px;">
                                <li>{{ $t('jackpot-terms.part-one') }}</li>
                                <li>{{ $t('jackpot-terms.part-two') }}</li>
                                <li>{{ $t('jackpot-terms.part-three') }}</li>
                                <li>{{ $t('jackpot-terms.part-four') }}</li>
                                <li>{{ $t('jackpot-terms.part-five') }}</li>
                                <li>{{ $t('jackpot-terms.part-six') }}</li>
                                <li>{{ $t('jackpot-terms.part-seven') }}</li>
                                <li>{{ $t('jackpot-terms.part-eight') }}</li>
                            </ul>

                            <h5><b>{{ $t('jackpot-bonus.part') }}</b></h5>
                            <ul style="list-style:lower-alpha;margin-left: -27px;">
                                <li>{{ $t('jackpot-bonus.part-one') }}</li>
                                <li>{{ $t('jackpot-bonus.part-two') }}</li>
                                <li>
                                    {{ $t('jackpot-bonus.part-three') }}
                                    <ul style="list-style:lower-roman;">
                                        <li>{{ $t('jackpot-bonus.part-three-one') }}</li>
                                        <li>{{ $t('jackpot-bonus.part-three-two') }}</li>
                                        <li>{{ $t('jackpot-bonus.part-three-three') }}</li>
                                        <li>{{ $t('jackpot-bonus.part-three-four') }}</li>
                                    </ul>
                                </li>
                                <li>{{ $t('jackpot-bonus.part-four') }}</li>
                                <li>{{ $t('jackpot-bonus.part-five') }}</li>
                                <li>{{ $t('jackpot-bonus.part-six') }}</li>
                            </ul>

                        </div>
                    </div> -->
                    <!-- <button class="terms-accordion" @click="toggleAccordian">{{  $t('autobet-terms.part') }}</button>
                    <div class="terms-conditions">
                        <div class="terms-body">
                            <ul style="list-style:lower-alpha;">
                                <li>{{  $t('autobet-terms.part-one') }}</li>
                                <li>{{  $t('autobet-terms.part-two') }}</li>
                                <li>{{  $t('autobet-terms.part-three') }}</li>
                                <li>{{  $t('autobet-terms.part-four') }}</li>
                                <li>{{  $t('autobet-terms.part-five') }}</li>
                                <li>{{  $t('autobet-terms.part-six') }}
                                    <ul style="list-style:lower-roman;">
                                        <li>{{  $t('autobet-terms.part-six-1') }}</li>
                                        <li>{{  $t('autobet-terms.part-six-2') }}</li>
                                    </ul>
                                </li>
                                <li>{{  $t('autobet-terms.part-seven') }}</li>
                                <li>{{  $t('autobet-terms.part-eight') }}</li>
                            </ul>
                        </div>
                    </div> -->
                    </div>
    </div>
    </div>
</template>

<script>
export default {
  name: 'terms-and-conditions',
  methods : {
    toggleAccordian: function(event) {
      var element = event.target;
      element.classList.toggle("active");
          
      var panel = element.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }
  }
}
</script>

<style scoped>

</style>